import React, { useReducer, useContext, useCallback, useState, useEffect } from "react";

import { formatDOB } from "../helpers.js";

import reducer from "./reducer.js";
import axios from "axios";
import {
	ACQUIRE_DATA_BEGIN,
	ACQUIRE_DATA_SUCCESS,
	SETUP_USER_BEGIN,
	SETUP_USER_SUCCESS,
	SETUP_USER_ERROR,
	CLEAR_ALERT,
	CLEAR_VALUES,
	DISPLAY_ALERT,
	GET_TESTS_BEGIN,
	GET_TESTS_SUCCESS,
	GET_TEST_TYPES_BEGIN,
	GET_TEST_TYPES_SUCCESS,
	SAVE_TEST_BEGIN,
	SAVE_TEST_SUCCESS,
	SAVE_TEST_ERROR,
	SHOW_REPORT_BEGIN,
	HANDLE_CHANGE,
	HANDLE_TOGGLE_COMPATIBILITY,
	SET_ACQUIRE_RUNNING,
	SET_MOCKED_TEST,
	SET_BYPASS_PHONE
} from "./actions.js";

const initialState = {
	userLoading: true,
	isLoading: false,
	showAlert: false,
	alertText: "",
	alertType: "",
	tests: [],
	testTypes: [],
	numOfPages: 1,
	testsTotal: 0,
	page: 0,
	testId: "",
	firstName: "",
	lastName: "",
	birthDate: "",
	gender: "",
	testType: "",
	eye: "",
	createdBy: "",
	data: [],
	reportData: "",
	riskIndication: "",
	status: "",
	bypassCompatibility:window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1', //to check if I am in localhost 
	isLocalHost:window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1', //to check if I am in localhost 
	isAcquireRunning: false,
	isTestMocked: localStorage.getItem("isTestMocked") === "true",
	isPhoneBypassed: localStorage.getItem("isPhoneBypassed") === "true"
};
const AppContext = React.createContext();

const AppProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [globalHealthStatus, setGlobalHealthStatus] = useState("checking");
	// Test
	const testFetch = axios.create({
		baseURL: `${import.meta.env.VITE_APP_SERVER_URI}/${import.meta.env.VITE_APP_TEST_SERVICE_API_VERSION}/`,
		withCredentials: true,
	});
	// to ensure localStorage values are updated when state changes
	useEffect(() => {
		localStorage.setItem("isTestMocked", state.isTestMocked);
		localStorage.setItem("isPhoneBypassed", state.isPhoneBypassed);
	}, [state.isTestMocked, state.isPhoneBypassed]);

	testFetch.interceptors.request.use(
		(req) => {
			req.headers["Content-Type"] = "application/json; charset=utf-8";
			// Include the CSRF token in the request headers
			const csrfToken = localStorage.getItem("csrfToken");

			if (csrfToken) {
				req.headers["X-CSRF-Token"] = csrfToken;
			}
			return req;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	testFetch.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	// Report
	const reportFetch = axios.create({
		baseURL: `${import.meta.env.VITE_APP_SERVER_URI}/${import.meta.env.VITE_APP_REPORT_SERVICE_API_VERSION}/`,
		responseType: "blob",
		withCredentials: true,
	});

	reportFetch.interceptors.request.use(
		(req) => {
			req.headers["Content-Type"] = "application/json; charset=utf-8";
			req.headers["Accept"] = "*/*";
			return req;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	reportFetch.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			console.log(error.response);
			return Promise.reject(error);
		}
	);

	// Data Acquire
	const dataAcquire = axios.create({
		baseURL: `${import.meta.env.VITE_APP_SERVER_URI}/${import.meta.env.VITE_APP_DATA_ACQUISITION_API_VERSION}/`,
		withCredentials: true,
	});

	dataAcquire.interceptors.request.use(
		(req) => {
			req.headers["Content-Type"] = "application/json; charset=utf-8";
			req.headers["Accept"] = "*/*";
			return req;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	dataAcquire.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			console.log(error.response);
			return Promise.reject(error);
		}
	);

	const displayAlert = () => {
		dispatch({ type: DISPLAY_ALERT });
		clearAlert();
	};

	const clearAlert = () => {
		setTimeout(() => {
			dispatch({ type: CLEAR_ALERT });
		}, 3000);
	};

	const handleChange = ({ name, value }) => {
		dispatch({ type: HANDLE_CHANGE, payload: { name, value } });
	};

	const clearValues = () => {
		dispatch({ type: CLEAR_VALUES });
	};

	const acquireData = async (testId, trajectoryName) => {
		dispatch({ type: ACQUIRE_DATA_BEGIN });
		try {
			let url = `/data/acquire/${testId}/${trajectoryName}`;
			const { data } = await dataAcquire.get(url);
			const { recordingId } = data;

			dispatch({
				type: ACQUIRE_DATA_SUCCESS,
				payload: { data },
			});
		} catch (error) {
			console.log(error.response);
		}
	};

	const saveTest = async (testData) => {
		dispatch({ type: SAVE_TEST_BEGIN });
		try {
			const {
				testId,
				firstName,
				lastName,
				dateOfBirth,
				gender,
				eye,
				notes,
				odSphere,
				odCylinder,
				odAxis,
				odAcuity,
				osSphere,
				osCylinder,
				osAxis,
				osAcuity,
				testType,
				createdBy,
				// clearValues,
			} = testData;

			let dob = dateOfBirth;

			// Convert to Date object if necessary
			if (!(dob instanceof Date)) {
				dob = new Date(dob);
			}

			const data = {
				testId: testId,
				firstName: firstName,
				lastName: lastName,
				dateOfBirth: formatDOB(dob),
				gender: gender,
				eye: eye,
				notes: notes,
				correction: {
					odSphere: odSphere,
					odCylinder: odCylinder,
					odAxis: odAxis,
					odAcuity: odAcuity,
					osSphere: osSphere,
					osCylinder: osCylinder,
					osAxis: osAxis,
					osAcuity: osAcuity,
				},
				testType: {
					value: testType.value,
					label: testType.label,
					disabled: testType.disabled,
					trajectories: testType.trajectories,
				},
				createdBy: createdBy,
			};
			const response = await testFetch.post("/tests", data);
			dispatch({ type: SAVE_TEST_SUCCESS });
			dispatch({ type: CLEAR_VALUES });
		} catch (error) {
			if (error.response.status === 401) return;
			dispatch({
				type: SAVE_TEST_ERROR,
				payload: { msg: error.response.data.msg },
			});
		}
		clearAlert();
	};

	//to check if there can be more than 100 tests:

	// const duplicateArray = (arr, n) => {
	// 	return Array.from({ length: n }, () => [...arr]).flat();
	//   };

	const getTests = useCallback(
		async (page = 0, testsPerPage = 10, sortBy = "testDate", sortOrder = "desc") => {
			let url = `tests?page=${page}&testsPerPage=${testsPerPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;
			dispatch({ type: GET_TESTS_BEGIN });
			try {
				const { data } = await testFetch.get(url);
				const { tests, testsTotal } = data;
				// place duplicatedTests as payload for tests (tests: duplicated tests) and multiply testTotal by 10

				// const duplicatedTests = duplicateArray(tests, 10);

				dispatch({
					type: GET_TESTS_SUCCESS,
					payload: {
						tests,
						testsTotal: testsTotal,
					},
				});
			} catch (error) {
				console.log(error.response);
			}
		},
		[dispatch]
	); // assuming testFetch.get is stable and doesn't change

	const getTestTypes = async () => {
		let url = `test-types`;
		dispatch({ type: GET_TEST_TYPES_BEGIN });
		try {
			const { data } = await testFetch.get(url);
			const { testTypes } = data;

			dispatch({
				type: GET_TEST_TYPES_SUCCESS,
				payload: {
					testTypes,
				},
			});
		} catch (error) {
			console.log(error.response);
		}
	};
	const deleteTestStep = async (testId, stepId) => {
		const csrfToken = localStorage.getItem("csrfToken");

		const headers = {
			"Content-Type": "application/json; charset=utf-8",
			"X-CSRF-Token": csrfToken, // Add the CSRF token to the request headers
		};

		try {
			const responseData = await axios.delete(
				`${import.meta.env.VITE_APP_SERVER_URI}/${
					import.meta.env.VITE_APP_REPORT_SERVICE_API_VERSION
				}/data/sessions/${testId}/${stepId}`,
				{
					responseType: "json",
					withCredentials: true,
					headers: headers,
				}
			);
		} catch (error) {
			console.log("Error deleting step:", error.message);
			if (error.response) {
				console.log("Error response:", error.response.status, error.response.statusText);
			}
		}
	};

	const setupUser = async ({ currentUser, endPoint, alertText }) => {
		dispatch({ type: SETUP_USER_BEGIN });
		try {
			const { data } = await axios.post(`/api/v1/auth/${endPoint}`, currentUser);

			const { user } = data;
			dispatch({
				type: SETUP_USER_SUCCESS,
				payload: { user, alertText },
			});
		} catch (error) {
			dispatch({
				type: SETUP_USER_ERROR,
				payload: { msg: error.response.data.msg },
			});
		}
		clearAlert();
	};

	const showReport = async (testId) => {
		dispatch({ type: SHOW_REPORT_BEGIN });

		let url = `reports/${testId}`;
		let reportURL;

		try {
			const data = await reportFetch.get(url);
			const file = new Blob([data], { type: "application/pdf" });
			reportURL = URL.createObjectURL(file);
		} catch (error) {
			console.error(error);
			// Here handle the error, for instance by updating some state
			this.setState({ error: error.message });
		}

		return reportURL;
	};

	const handleSelect = async (event) => {
		dispatch({
			type: HANDLE_CHANGE,
			payload: event.target.value,
		});
	};
	const toggleCompatibility = async (event) => {
		dispatch({
			type: HANDLE_TOGGLE_COMPATIBILITY,
			payload: event,
		});
		localStorage.setItem("compatibility", event);
	};

	const setAcquireRunning = (isRunning) => {
		dispatch({ type: SET_ACQUIRE_RUNNING, payload: isRunning });
	  };
	const toggleMockTest = (isTestMocked) => {
		dispatch({ type: SET_MOCKED_TEST, payload: isTestMocked });
		localStorage.setItem("isTestMocked",isTestMocked)
	}
	const togglePhoneBypass = (isPhoneBypassed) => {
		dispatch({ type: SET_BYPASS_PHONE, payload: isPhoneBypassed });
		localStorage.setItem("isPhoneBypassed",isPhoneBypassed)
	}


	return (
		<AppContext.Provider
			value={{
				...state,
				acquireData,
				getTests,
				getTestTypes,
				saveTest,
				clearValues,
				showReport,
				setupUser,
				handleChange,
				handleSelect,
				toggleCompatibility,
				setAcquireRunning,
				setGlobalHealthStatus,
				globalHealthStatus,
				toggleMockTest,
				togglePhoneBypass
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

const useAppContext = () => {
	return useContext(AppContext);
};

export { AppProvider, initialState, useAppContext };
