import React, { useEffect, useState } from "react";
import { MdHelpCenter, MdScatterPlot, MdStackedLineChart, MdSettings, MdSupportAgent } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { Toolbar, List, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { AppBar } from "@material-ui/core";
import { styled } from "@mui/system";
import bn from "../../utils/bemnames";
import { useBackendStatusContext } from "../../context/backendStatus/backendStatusContext";
import { useTranslation } from "../../context/TranslationContext";
import logo200ImageWhite from "../../images/logo_white.png";
import SourceLink from "../SourceLink";
import { useSidebar } from "../../context/SidebarContext";

const sidebarBackground = {
	backgroundSize: "cover",
	backgroundRepeat: "no-repeat",
};

const bem = bn.create("sidebar");

const StyledNavLink = styled(NavLink)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(1),
	color: 'inherit',
	textDecoration: 'none',
	'&:hover': {
		color: 'white',
		backgroundColor: theme.palette.action.hover,
	},
	'&.active': {
		color: 'white',
		backgroundColor: theme.palette.action.selected,
	},
}));

const basicNavItems = [
	{ to: "/support", name: "navItems.support", Icon: MdSupportAgent },
	{ to: "/about", name: "navItems.about", Icon: MdHelpCenter },
];

const extendedNavItems = [
	{ to: "/", name: "navItems.visualTests", Icon: MdScatterPlot },
	{ to: "/results", name: "navItems.results", Icon: MdStackedLineChart },
	{ to: "/settings", name: "navItems.settings", Icon: MdSettings },
	{ to: "/support", name: "navItems.support", Icon: MdSupportAgent },
	{ to: "/about", name: "navItems.about", Icon: MdHelpCenter },
];

const getNavItems = (translate, isExtended) => {
	const items = isExtended ? extendedNavItems : basicNavItems;
	return items.map(item => ({ ...item, name: translate(item.name) }));
};

const Sidebar = () => {
	const backendStatus = useBackendStatusContext();
	const { translate } = useTranslation();

	const [navItems, setNavItems] = useState(getNavItems(translate, false));
	const { isSidebarOpen, closeSidebar } = useSidebar();


	useEffect(() => {
		setNavItems(extendedNavItems);
	}, []);
	const handleNavItemClick = () => {
		if (window.innerWidth <= 768) {
			closeSidebar();
		}
	};
		
	useEffect(() => {
		const isExtended = (backendStatus.state.isAvailable && backendStatus.state.isCertificateValid) || backendStatus.state.isUpdating;
		setNavItems(getNavItems(translate, isExtended));
	}, [backendStatus.state.isAvailable, backendStatus.state.isCertificateValid, backendStatus.state.isUpdating, translate]);

	return (
		<aside className={`${bem.b()} ${isSidebarOpen ? 'ss-sidebar--open' : ''}`} data-image={logo200ImageWhite}>
			<div className={bem.e("background")} style={sidebarBackground} />
			<div className={bem.e("content")}>
				<AppBar position="static" color="transparent" elevation={0}>
					<Toolbar>
						<SourceLink className="d-flex" style={{ textDecoration: "none" }}>
							<span className={bem.e("nav-item")} style={{ color: "white", display: "flex", alignItems: "center", marginLeft: "-10px" }}>
								<img src={logo200ImageWhite} width="20" height="24" className="pr-2" alt="" style={{ margin: "0px 8px 2px 6px" }} />
								<Typography variant="h6" style={{ fontWeight: "bold", fontSize: "22px" }}>SONDA</Typography>
							</span>
						</SourceLink>
					</Toolbar>
				</AppBar>
				<List>
					{navItems.map(({ to, name, Icon }, index) => (
						<StyledNavLink
							key={index}
							to={to}
							activeClassName="active"
							onClick={handleNavItemClick}
						>
							<ListItemIcon>
								<Icon className={bem.e("nav-item-icon")} style={{ color: "white" }} />
							</ListItemIcon>
							<ListItemText primary={name} style={{ textTransform: "uppercase", marginLeft: "-20px" }} />
						</StyledNavLink>
					))}
				</List>
			</div>
		</aside>
	);
};

export default Sidebar;
